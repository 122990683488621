class SideDrawer extends HTMLElement {
  constructor() {
    super();
    this._id = this.getAttribute('id');
    this._overlay = this.querySelector(`#SideDrawer-Overlay-${this._id}`);
    this._drawer = this.querySelector(`#SideDrawer-${this._id}`);
    this._closeButton = this.querySelector(`.drawer__close`);
    this.bindToggles();
  }

  bindToggles() {
    const triggerSelector = this.getAttribute('trigger-element-selector');
    // Only bind to triggers that specifically target this drawer
    this._triggers = document.querySelectorAll(`${triggerSelector}[data-drawer-target="${this._id}"]`);

    this._overlay.addEventListener('click', () => this.close());
    this._closeButton.addEventListener('click', () => this.close());

    this._triggers.forEach((trigger) => {
      trigger.setAttribute('role', 'button');
      trigger.setAttribute('aria-haspopup', 'dialog');
      trigger.addEventListener('click', (event) => {
        event.preventDefault();
        this.toggle();
      });
    });
  }

  isOpen() {
    return this.hasAttribute('open');
  }

  toggle() {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.setAttribute('open', '');
    this.classList.remove('translate-x-full');
    document.body.classList.add('overflow-hidden');
  }

  close() {
    this.removeAttribute('open');
    this.classList.add('translate-x-full');
    document.body.classList.remove('overflow-hidden');
  }
}

customElements.define('side-drawer', SideDrawer);
